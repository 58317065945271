import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import mainLogo from './ratio_logo.svg';
import fidalLogo from './fidalLogo.jpg';
import Login from './Login/Login';
import { Context } from "./Context.js";
import { useState } from 'react';
import { Avatar, Grid } from '@mui/material';




export default function InfoUser() {

    const { nome, setNome, token, setToken } = useContext(Context)




    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Atletica Alto Lario - UTENTE
                    </Typography>
                    <Typography variant="h6" component="div" dx={{ flexGrow: 1 }}>
                        {nome}
                    </Typography>

                </Toolbar>
            </AppBar>

        </Box>
    );



}
import { Paper, Grid, Button, Checkbox, FormGroup, Alert } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React, { useContext } from 'react';

import { loadStripe } from "@stripe/stripe-js";

import constant from '../Costanti/Constant.js';
import 'dayjs/locale/it';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { useNavigate } from "react-router-dom";
import InfoUser from '../infoUser';
import { Context } from "../Context.js";

//const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


const Utenze = {
    ADMIN: 1,
    USER: 2
}



const Abbonamento = () => {

    const stripePromise = loadStripe("pk_live_51LvMKTIUBaKEna1TVuf3hbmFwVkBBzOEfsm0M7Jp6vsm0i7UsuPG87XyGnD77ANmLPs38bhhKECYn43B0fF9geSL00efgDLihY");
    //const stripePromise = loadStripe("pk_test_51LvMKTIUBaKEna1TRnOLWiJT9RIK89r8m7UIacjqQ83SbIUOgNm3MphWaXYcYUflSnKuTsWV8P5T3wgr1Nxtgduc00difhqW1S");

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)


    const [costoAbbonamento, setCostoAbbonamento] = React.useState('0');
    const [tipoAbbonamento, setTipoAbbonamento] = React.useState('');

    //const bookableFiieds = [];
    const [bookableField, setBookableField] = React.useState([]);
    const [selectedFields, _] = React.useState(new Set());
    const [totale, setTotale] = React.useState('0');
    const [num, setNum] = React.useState('0');
    const [alertError, setAlertError] = React.useState(false);
    const [alertOk, setAlertOk] = React.useState(false);

    const [DatePickerAnno, setDatePickerAnno] = React.useState(false);
    const [DatePickerMese, setDatePickerMese] = React.useState(false);
    const [DatePickerGiorno, setDatePickerGiorno] = React.useState(false);

    const [msgAlert, setMsgAlert] = React.useState('');
    const [valueData, setValueData] = React.useState(dayjs());

    const [dataFineAbbonamento, setDataFineAbbonamento] = React.useState('');

    const [visualizzaPagina, setVisualizzaPagina] = React.useState(false);
    const [isClickableButton, setIsClickableButton] = React.useState(true);


    const navigate = useNavigate();




    // const [matches, setMatches] = React.useState(
    //     window.matchMedia("(min-width: 768px)").matches
    // )

    //Questo mi serve per settare la dimensione del foglio in base alla risoluzione del monitor
    React.useEffect(() => {
        // window
        //     .matchMedia("(min-width: 768px)")
        //     .addEventListener('change', e => setMatches(e.matches));
    }, []);


    React.useEffect(() => { // Pass in a callback function!
        console.log(`idtipo ${idTipoUtente}`)
        if (idTipoUtente != Utenze.USER) {
            navigate('/');
        } else {
            setVisualizzaPagina(true);
        }
    }, []);


    React.useEffect(() => { // Pass in a callback function!
        console.log(`Bearer ${token}`)
        richiestaCampi()
    }, []);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const richiestaCampi = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(constant.RICHIESTACAMPI, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json();

                }
            })//response.json()
            .then(fieldList => setBookableField(fieldList.map(field => field["NomeCampo"])))
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    // const richiestaCampi = () => {



    //     fetch("http://localhost:3210/Progetti/fidal-bs/php-backend/richiestaCampi.php", {
    //         method: 'post',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Access-Control-Allow-Origin': '*',
    //             'Access-Control-Allow-Headers': '*',
    //             'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
    //             'Authorization': `Bearer ${token}`,
    //         }
    //     })
    //         .then(response => response.json()
    //             .then(fieldList => setBookableField(fieldList.map(field => field["NomeCampo"])))
    //         )


    //     //.then(response => {
    //     //    if (!response.ok) throw console.log("errore");
    //     //    else return response.json();
    //     //  })//response.json()
    //     //  .then(fieldList => setBookableField(fieldList.map(field => field["NomeCampo"])))
    // }

    const handleChange = (event) => {
        setCostoAbbonamento(event.target.value)
        if (event.target.name === "Annuale") {
            setTipoAbbonamento("Annuale")
            setDatePickerAnno(true)
            setDatePickerMese(false)
            setDatePickerGiorno(false)
        }
        if (event.target.name === "Mensile") {
            setTipoAbbonamento("Mensile")
            setDatePickerAnno(false)
            setDatePickerMese(true)
            setDatePickerGiorno(false)
        }
        if (event.target.name === "Trimestrale") {
            setTipoAbbonamento("Trimestrale")
            setDatePickerAnno(false)
            setDatePickerMese(true)
            setDatePickerGiorno(false)
        }
        if (event.target.name === "Giornaliero") {
            setTipoAbbonamento("Giornaliero")
            setDatePickerAnno(false)
            setDatePickerMese(false)
            setDatePickerGiorno(true)
        }
    }

    const isBookableFieldChecked = (index) => {
        console.log('Im checked' + index + selectedFields.has(index))
        selectedFields.has(index)
    }

    const checkBookableField = (event, index) => {
        console.log(event.target.value)
        if (event.target.checked) {
            selectedFields.add(index + 1)
        }
        else {
            selectedFields.delete(index + 1)

        }
        console.log(selectedFields)
        console.log(selectedFields.size)
        setNum(selectedFields.size)
    };



    const buttonCLickPagamento = () => {
        buttonCLick()
        //navigate('/pagamento')
    }




    const createCheckoutSession = (dataInizioAbbonamento, dataFineAbbonamento) => {

        let arrayCampi = []
        if (num === 2) {
            arrayCampi = [1, 2]
        } else {
            arrayCampi = [1]
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var min = today.getMinutes();
        var sec = today.getSeconds();


        if (hh < 10) {
            hh = '0' + hh;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }

        today = yyyy + mm + dd + hh + min + sec;

        let arrCampi = [...selectedFields];

        console.log(num);
        console.log(arrCampi);
        console.log(dataInizioAbbonamento);
        console.log(dataFineAbbonamento);
        console.log("0");
        console.log(today);
        console.log(today);


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", '*')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(
                {
                    createCheckoutSession: 1,
                    "nomeProdotto": tipoAbbonamento,
                    "numeroCampi": num,
                    "idCampo": arrCampi[0],
                    "dataInizio": dataInizioAbbonamento,
                    "dataFine": dataFineAbbonamento,
                    "tipoAbbonamento": tipoAbbonamento,
                    "confermato": "0",
                    "insDate": today,
                    "updDate": today
                }

            ),
            redirect: 'follow'
        };

        fetch(constant.BASE_URL + "payment_init.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json().then(data => {
                        stripePromise.then(stripe => {
                            stripe.redirectToCheckout({
                                sessionId: data.sessionId,
                            });
                        })
                    });
                }
            }).then(handleResult)


    };

    const handleResult = (result) => {
        if (result.error) {
            console.log(result.error.message);
        }
    };

    const buttonCLick = () => {


        setIsClickableButton(false);
        let _dataInizioAbbonamento = '';
        let _dataFineAbbonamento = '';

        if (num == 0) {
            setMsgAlert("Errore: selezionare un abbonamento")
            setAlertError(true);
            setAlertOk(false);
            setIsClickableButton(true);
        } else {
            setAlertError(false);
            if (tipoAbbonamento === "Giornaliero") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();
                let giornoInizio = valueData.date().toString();

                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                if (giornoInizio < 10) {
                    giornoInizio = `0${giornoInizio}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}${giornoInizio}000001`);
                _dataFineAbbonamento = (`${annoInizio}${meseInizio}${giornoInizio}235959`);
            }

            if (tipoAbbonamento === "Mensile") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();

                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}01000001`);
                if (meseInizio === '02') {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}28235959`);
                } else if (meseInizio === '04' || meseInizio === '06' || meseInizio === '09' || meseInizio === '11') {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}30235959`);
                } else {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}31235959`);
                }


            }

            if (tipoAbbonamento === "Trimestrale") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();
                let meseFine = (valueData.month() + 3).toString();
                if (valueData.month() > 9) {

                    meseFine = (12).toString();
                    console.log(meseFine);
                }


                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                if (meseFine < 10) {
                    meseFine = `0${meseFine}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}01000001`);
                if (meseFine === '02') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}28235959`);
                } else if (meseFine === '04' || meseFine === '06' || meseFine === '09' || meseFine === '11') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}30235959`);
                } else {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}31235959`);
                }


            }

            if (tipoAbbonamento === "Annuale") {
                let annoInizio = valueData.year().toString();

                _dataInizioAbbonamento = (`${annoInizio}0101000001`);
                _dataFineAbbonamento = (`${annoInizio}1231235959`);
            }


            createCheckoutSession(_dataInizioAbbonamento, _dataFineAbbonamento,)
            //sendApiData();
        }
    }

    // React.useEffect(() => {
    //     console.log(dataInizioAbbonamento)

    // }, [dataInizioAbbonamento]);

    // const sendApiData = (dataInizioAbbonamento, dataFineAbbonamento) => {
    //     let arrayCampi = []
    //     if (num === 2) {
    //         arrayCampi = [1, 2]
    //     } else {
    //         arrayCampi = [1]
    //     }

    //     var today = new Date();
    //     var dd = String(today.getDate()).padStart(2, '0');
    //     var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    //     var yyyy = today.getFullYear();
    //     var hh = today.getHours();
    //     var min = today.getMinutes();
    //     var sec = today.getSeconds();


    //     if (hh < 10) {
    //         hh = '0' + hh;
    //     }
    //     if (min < 10) {
    //         min = '0' + min;
    //     }
    //     if (sec < 10) {
    //         sec = '0' + sec;
    //     }

    //     today = yyyy + mm + dd + hh + min + sec;

    //     let arrCampi = [...selectedFields];

    //     console.log(num);
    //     console.log(arrCampi);
    //     console.log(dataInizioAbbonamento);
    //     console.log(dataFineAbbonamento);
    //     console.log("0");
    //     console.log(today);
    //     console.log(today);

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", 'application/json')
    //     myHeaders.append("Access-Control-Allow-Origin", '*')
    //     myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
    //     myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
    //     myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
    //     myHeaders.append("Pragma", 'no-cache')
    //     myHeaders.append("Authorization", `Bearer ${token}`);
    //     //myHeaders.append("Authorization", `Bearer fdasfa`);


    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         redirect: 'follow',
    //         body: JSON.stringify({
    //             "idUser": "3",
    //             "numeroCampi": num,
    //             "idCampo": arrCampi,
    //             "dataInizio": dataInizioAbbonamento,
    //             "dataFine": dataFineAbbonamento,
    //             "tipoAbbonamento": tipoAbbonamento,
    //             "confermato": "0",
    //             "insDate": today,
    //             "updDate": today,
    //             
    //         })
    //     };




    //     fetch(constant.INSERTABBONAMENTO, requestOptions)
    //         .then(response => {
    //             return response.json()
    //                 .then(json => gestioneJson(json))

    //             //     })
    //         })
    // }


    const gestioneJson = async (json) => {
        console.log(json)
        const _risultato = json["risultato"]
        //back tick = alt + 96
        console.log(`risultato: ${_risultato}`)
        if (_risultato == 200) {
            setMsgAlert("Abbonamento inserito correttamente\nRiceverai una email di conferma\nA breve verrai indirizzato alla pagina di login");
            setAlertOk(true);
            setAlertError(false);
            await delay(5000);
            navigate('/login')
        } else {
            setMsgAlert("Errore inserimento abbonamento");
            setAlertError(true);
            setAlertOk(false);
            setIsClickableButton(true);

        }

    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const paerStyle = {
        padding: 20,
        height: '70vh',
        // width: matches ? '800px' : '300px',
        width: '800px',
        margin: '10px auto'
    }

    return (

        <div style={{
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
            // justifyContent: 'center',
            // // Change the size to fit the parent element of this div
            // width: '100%',
            // height: '100%',

            padding: 20,
            // width: matches ? '800px' : '300px',
            width: '75%',
            margin: 'auto'
        }}>
            {visualizzaPagina == true ?
                <Grid>
                    <InfoUser />
                    {/* <Paper elevation={10} style={paerStyle}> */}
                    <Grid align='center'>
                        <h2>Campo</h2>
                        <FormGroup>
                            {
                                bookableField.map((item, index) => {
                                    console.log("FIELD: " + item);
                                    return (
                                        <FormControlLabel
                                            checked={isBookableFieldChecked(index)}
                                            control={
                                                <Checkbox
                                                    onChange={(event) => checkBookableField(event, index)}
                                                />} label={item}
                                            key={index}
                                        />
                                    )
                                })

                            }
                        </FormGroup>

                        <ColoredLine color="black" />

                        <FormControl>
                            <h2 id="demo-radio-buttons-group-label">Periodo</h2>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value={5} name={"Giornaliero"} control={<Radio onChange={handleChange} />} label="Giornaliero" />
                                <FormControlLabel value={10} name={"Mensile"} control={<Radio onChange={handleChange} />} label="Mensile" />
                                <FormControlLabel value={25} name={"Trimestrale"} control={<Radio onChange={handleChange} />} label="Trimestrale" />
                                <FormControlLabel value={90} name={"Annuale"} control={<Radio onChange={handleChange} />} label="Annuale" />
                            </RadioGroup>
                        </FormControl>
                        <ColoredLine color="black" />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                            <h2>Calendario</h2>
                            <Stack spacing={3}>
                                {DatePickerAnno ? <DatePicker
                                    views={['year']}
                                    label="Anno"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerMese ? <DatePicker
                                    views={['year', 'month']}
                                    label="Anno e mese di inizio"
                                    minDate={dayjs(dayjs())}
                                    //maxDate={dayjs('2023-06-01')}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerGiorno ? <DatePicker
                                    views={['day']}
                                    label="Data"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                            </Stack>
                        </LocalizationProvider>
                        <ColoredLine color="black" />
                        <h1>Totale: {num * costoAbbonamento} € </h1>
                        <ColoredLine color="black" />
                        <Button disabled={isClickableButton ? false : true} variant="contained" margin='normal' size="medium" fullWidth onClick={buttonCLickPagamento}>
                            Scegli piano
                        </Button>
                    </Grid>
                    {alertError ? <Alert padding='20' severity='error'>{msgAlert}</Alert> : <></>}
                    {alertOk ? <Alert severity='success'>{msgAlert}</Alert> : <></>}
                    {/* </Paper> */}
                </Grid> : <></>}
        </div>
    )
}

export default Abbonamento;


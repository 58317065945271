
import { DataGrid, itIT, GridToolbar } from '@mui/x-data-grid';
import { Grid, Paper } from '@mui/material';
import InfoUser from './infoUser.js';
import constant from './Costanti/Constant.js'

import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from "./Context.js";


import { createTheme, ThemeProvider } from "@mui/material/styles";

import imageLogoFidal from "./img/logoFidal.jpeg";
import InfoUserAdmin from './InfoUserAdmin.js';



const Utenze = {
    ADMIN: 1,
    USER: 2
}


const columns = [
    { field: 'id', headerName: 'id', width: 70 },
    { field: 'Nome', headerName: 'Nome e Cognome', width: 300 },
    { field: 'Mail', headerName: 'Email', width: 300 },
    { field: 'NumeroDiTelefono', headerName: 'Numero di Telefono', width: 300 },
    { field: 'Indirizzo', headerName: 'Indirizzo', width: 300 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];

const rows = [];

const theme = createTheme({}, itIT);

export default function UserList() {

    const navigate = useNavigate();

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)


    const [righe, setRighe] = React.useState([]);
    const [visualizzaPagina, setVisualizzaPagina] = React.useState(false);


    React.useEffect(() => { // Pass in a callback function!
        console.log(`idtipo ${idTipoUtente}`)
        if (idTipoUtente != Utenze.ADMIN) {
            navigate('/');
        } else {
            setVisualizzaPagina(true);
        }
    }, []);

    React.useEffect(() => { // Pass in a callback function!
        console.log("test use effect")
        richiestaAbbonamenti()
    }, []);


    const paerStyle = {
        padding: 20,
        height: '70vh',
        width: '80%',
        margin: '80px auto',
        //backgroundImage: `url(${imageLogoFidal})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    }





    const richiestaAbbonamenti = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(constant.BASE_URL + "richiestaUtenti.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json();

                }
            })//response.json()
            .then(json => gestioneJsonRitornoCampi(json))
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    function gestioneJsonRitornoCampi(json) {
        console.log(json);
        setRighe(json);

    }

    return (

        // <div style={{

        //     padding: 20,
        //     width: '85%',
        //     margin: 'auto'

        // }}>
        <div>
            <Grid>
                <InfoUserAdmin />
                <Paper elevation={24} style={paerStyle}>

                    <DataGrid
                        rows={righe}
                        columns={columns}
                        getRowId={(row) => row.id}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        //checkboxSelection
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: GridToolbar
                        }}
                    />

                </Paper>
            </Grid>

        </div>
    );
}

import { FormControlLabel, Grid, Box, Button, Alert, Switch, TextField, FormControl } from '@mui/material';
import 'dayjs/locale/it';

import React from 'react';
import { useNavigate } from "react-router-dom";

import constant from './Costanti/Constant.js';

import './RecuperoPass.css'


const RecuperoPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = React.useState('')
    const [msgAlert, setMsgAlert] = React.useState('');

    const [isSocieta, setIsSocieta] = React.useState(false);

    const [alertError, setAlertError] = React.useState(false);
    const [alertOk, setAlertOk] = React.useState(false);



    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    function handle() {
        sendData();
    }

    const sendData = () => {

        let isSocietaDaSpedire;

        if(isSocieta){
            isSocietaDaSpedire = 1;
        }else{
            isSocietaDaSpedire = 0;
        }

        const body = JSON.stringify({
            "mail": email,
            "isSocieta": isSocietaDaSpedire
        });

        fetch(constant.BASE_URL + "sendLinkResetPass.php", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
            },
            body: body,
        })
            .then(response => {
                if (!response.ok) {
                    console.log(response.status)
                    if (response.status === 401) {
                        throw setMsgAlert("Utente e/o password errati");
                    } else if (response.status === 500) {
                        throw setMsgAlert("Uno dei campi di login e\' vuoto");
                    } else {
                        throw setMsgAlert("Errore");
                    }


                }else{
                    setMsgAlert("Email inviata correttamente, segui la procedura che ti verrà comunicata nell'email");
                    setAlertOk();

                }

            })//response.json()
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'left',
            // Change the size to fit the parent element of this div
            width: '100%',
            height: '100%',
        }}>
            <Grid align='center'>
                {/* <Paper elevation={10} style={paerStyle}> */}
                <div align='center'>
                    <h2>Recupera password</h2>
                    <ColoredLine color="black" />
                    <Grid align='center'>
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={<Switch align='center' color="primary" />}
                                label={isSocieta ? "SOCIETA'" : "UTENTE"}
                                align='center'
                                onChange={() => setIsSocieta(prevCheck => !prevCheck)}
                                value={isSocieta}
                                checked={!!isSocieta}
                            />
                        </FormControl>
                    </Grid>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid align='center'>
                            <TextField id="emailTF" className={'input-text'} label="Email" onChange={(event) => { setEmail(event.target.value) }} />
                        </Grid>
                    </Box>

                    <Grid
                        paddingTop={3}
                        paddingLeft={50}
                        paddingRight={50}
                        className={'recupera-password-button-page'}
                    >
                        <Button variant="contained" margin='normal' size="medium" onClick={handle}>
                            Manda email per il recupero password
                        </Button>
                    </Grid>
                </div>
                {alertError ? <Alert padding='20' severity='error'>{msgAlert}</Alert> : <></>}
                {alertOk ? <Alert severity='success'>{msgAlert}</Alert> : <></>}
                {/* </Paper> */}
            </Grid >
        </div >

    );
}

export default RecuperoPassword;

import { Grid, Paper, Avatar, TextField, Button, Link, Alert, FormControl, IconButton, FormControlLabel, Switch, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import React, { useContext } from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import mainLogo from '../ratio_logo.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import constant from '../Costanti/Constant.js';
import InfoUser from '../infoUser';

import { Context } from "../Context.js";
import './Login.css'

import image from "../img/sfondo.jpeg";
//import imageLogoFidalIcona from "../img/logoFidalIcona.jpeg";
import imagelogoFidalComo from "../img/logoComo.jpg";


const Utenze = {
  ADMIN: 1,
  USER: 2,
  SOCIETA: 3,
  OPERATORE: 5
}

const Login = () => {

  const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)

  const [user, setUser] = useState('');
  const [pass, setPassword] = useState('');
  const [isSocieta, setIsSocieta] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  //const [alert, setAlert] = React.useState(false);
  const [msgAlert, setMsgAlert] = React.useState('');

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //Questo mi serve per settare la dimensione del foglio in base alla risoluzione del monitor
  React.useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);




  const getApiData = () => {


    const body = JSON.stringify({
      "mail": user,
      "password": pass,
      "societa": isSocieta
    });

    fetch(constant.LOGIN, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
      body: body,
    })
      .then(response => {
        if (!response.ok) {
          console.log(response.status)
          if (response.status === 401) {
            throw setMsgAlert("Utente e/o password errati");
          } else if (response.status === 500) {
            throw setMsgAlert("Uno dei campi di login e\' vuoto");
          } else {
            throw setMsgAlert("Errore");
          }


        }
        else return response.json();
      })//response.json()
      .then(json => gestioneJson(json))
  }


  const gestioneJson = (json) => {

    console.log(json)



    if (json["errore"] == 0) {

      const _idTipoUtente = json["idTipoUtente"]
      const _Mail = json["userMail"]
      const _token = json["token"]
      const _nome = json["nome"]
      //back tick = alt + 96
      //console.log(`nome: ${_nome}`)

      setNome((nome) => _nome)
      setToken((token) => _token)
      setidTipoUtente((idTipoUtente) => _idTipoUtente)

      console.log(`Utenze.ADMIN =  ${Utenze.SOCIETA}`)
      console.log(`_idTipoUtente = ${_idTipoUtente}`)
      if (_idTipoUtente == Utenze.USER) {
        navigate('/abbonamento')
      }
      if (_idTipoUtente == Utenze.ADMIN || _idTipoUtente == Utenze.OPERATORE) {
        navigate('/listaAbbonamenti')
      }
      if (_idTipoUtente == Utenze.SOCIETA) {
        navigate('/abbonamentoSocietaGruppo')
      }
    } else if (json["errore"] == 501) {
      setMsgAlert("Utente e/o password errati")
    }

  }


  function handle() {
    console.log(isSocieta)
    const concatenazione = user + "\n" + pass
    getApiData();
  }

  function goToIscrizione() {
    navigate('/iscrizione');
  }

  function goToRecuperaPass() {
    navigate('/recuperoPassword');
  }


  const paerStyle = {
    padding: 20,
    height: '55vh',
    width: matches ? '50%' : '80%',
    margin: '1px auto'
  }

  const avatarStyle = {
    backgroundColor: '#4b4bff',
    width: 56,
    height: 56
  }

  return (

    <div style={{
      backgroundImage: `url(${image})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh'

    }}>


      <Grid style={{ overflowY: 'hidden', width: '100%' }}>
        <Paper elevation={10} className={'loginMobile'} style={paerStyle}>
          <Grid align='center' className={'logoContainer'}>
            <div className={'fidalRatioContainer'}>
              <img src={imagelogoFidalComo} className={'logoFidal'} style={{ height: 91, width: 150, float: 'right' }} alt="fireSpot" />
              <img src={mainLogo} className={'logoRatio'} style={{ height: 110, width: 110, float: 'left' }} alt="fireSpot" />
            </div>
            <Avatar className={'lock'} style={avatarStyle}>
              <LockIcon></LockIcon>
            </Avatar>
            <h2 className={'loginAccedi'}>ACCEDI</h2>
          </Grid>
          <Grid align='center'>
            <FormControl component="fieldset">
              <FormControlLabel
                control={<Switch align='center' color="primary" />}
                //label="SOCIETA'"
                label={isSocieta ? "SOCIETA'" : "UTENTE"}
                align='center'
                onChange={() => setIsSocieta(prevCheck => !prevCheck)}
                value={isSocieta}
                checked={!!isSocieta}
              />
            </FormControl>
            <div></div>
            {msgAlert.length != 0 ? <Alert className={'alertmsg'} severity='error'>{msgAlert}</Alert> : <></>}
            <FormControl sx={{ m: 1, width: '50ch' }} className={'input-username'} variant="outlined">
              <TextField label='Username' margin='normal' fullWidth value={user} onChange={(event) => { setUser(event.target.value) }}></TextField>
            </FormControl>
            {/* <TextField label='Password' margin='normal' type='password' fullWidth value={pass} onChange={(event) => { setPassword(event.target.value) }}></TextField> */}
            <div></div>
            <FormControl
              className='input-password'
              sx={{ m: 1, width: '50ch' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
                margin='normal'
                fullWidth
                value={pass}
                onChange={(event) => { setPassword(event.target.value) }}
              />
            </FormControl>
          </Grid>
          <Grid align="center">
            <Button className={'accedi-button'} variant="contained" margin='normal' size="medium" onClick={handle}>
              Accedi
            </Button>
          </Grid>
          <Grid align="center" >
            <Link onClick={goToIscrizione} className={'iscriviti-button'} href="#">Iscriviti</Link>
            <div>
              <Link onClick={goToRecuperaPass} className={'recupera-password-button'} href="#">Recupera password</Link>
            </div>
          </Grid>
        </Paper>

      </Grid>
    </div>
  );

}

export default Login;

import { Paper, Grid, Button, Checkbox, FormGroup, Alert, Switch } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React, { useContext } from 'react';


import constant from '../Costanti/Constant.js';
import 'dayjs/locale/it';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { loadStripe } from "@stripe/stripe-js";

import { useNavigate } from "react-router-dom";
import InfoUserSocieta from '../infoUserSocieta';
import { Context } from "../Context.js";
import { ColorLensOutlined, CoPresentOutlined } from '@mui/icons-material';

//const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


const Utenze = {
    ADMIN: 1,
    USER: 2,
    SOCIETA: 3
}



const AbbonamentoSocietaGruppo = () => {

    const stripePromise = loadStripe("pk_live_51LvMKTIUBaKEna1TVuf3hbmFwVkBBzOEfsm0M7Jp6vsm0i7UsuPG87XyGnD77ANmLPs38bhhKECYn43B0fF9geSL00efgDLihY");
    //const stripePromise = loadStripe("pk_test_51LvMKTIUBaKEna1TRnOLWiJT9RIK89r8m7UIacjqQ83SbIUOgNm3MphWaXYcYUflSnKuTsWV8P5T3wgr1Nxtgduc00difhqW1S");

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)

    const navigate = useNavigate();


    const [costoAbbonamento, setCostoAbbonamento] = React.useState('0');
    const [tipoAbbonamento, setTipoAbbonamento] = React.useState('');
    const [tipoGruppo, setTipoGruppo] = React.useState('');

    const [isRichiediFattura, setIsRichiediFattura] = React.useState(false);
    const [costoTotale, setCostoTotale] = React.useState(0);


    const [bookableField, setBookableField] = React.useState([]);
    const [selectedFields, _] = React.useState(new Set());
    const [num, setNum] = React.useState('0');
    const [alertError, setAlertError] = React.useState(false);
    const [alertOk, setAlertOk] = React.useState(false);

    const [DatePickerAnno, setDatePickerAnno] = React.useState(false);
    const [DatePickerMese, setDatePickerMese] = React.useState(false);
    const [DatePickerGiorno, setDatePickerGiorno] = React.useState(false);

    const [msgAlert, setMsgAlert] = React.useState('');
    const [valueData, setValueData] = React.useState(dayjs());


    const [visualizzaPagina, setVisualizzaPagina] = React.useState(false);
    const [isClickableButton, setIsClickableButton] = React.useState(true);



    React.useEffect(() => { // Pass in a callback function!
        if (idTipoUtente != Utenze.SOCIETA) {
            navigate('/');
        } else {
            setVisualizzaPagina(true);
        }
    }, []);


    React.useEffect(() => { // Pass in a callback function!
        richiestaCampi()
    }, []);

    React.useEffect(() => {
        // here, values has already been updated
        calcoloCostoAbbonamento()
    }, [tipoAbbonamento, tipoGruppo])

    const richiestaCampi = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(constant.RICHIESTACAMPI, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json();

                }
            })//response.json()
            .then(fieldList => setBookableField(fieldList.map(field => field["NomeCampo"])))
            .catch(error => console.log('error', error));
    }

    const calcoloCostoAbbonamento = () => {

        if (tipoGruppo != '' && tipoAbbonamento != '' && selectedFields.size != 0) {
            if (tipoGruppo === '20') {
                if (tipoAbbonamento == "Mensile") {
                    setCostoAbbonamento('100')
                } else if (tipoAbbonamento == "Trimestrale") {
                    setCostoAbbonamento('250')
                } else {
                    setCostoAbbonamento('900')
                }
            }

            if (tipoGruppo === '40') {
                if (tipoAbbonamento == "Mensile") {
                    setCostoAbbonamento('200')
                } else if (tipoAbbonamento == "Trimestrale") {
                    setCostoAbbonamento('500')
                } else {
                    setCostoAbbonamento('1800')
                }
            }

            if (tipoGruppo === '41') {
                if (tipoAbbonamento == "Mensile") {
                    setCostoAbbonamento('400')
                } else if (tipoAbbonamento === "Trimestrale") {
                    setCostoAbbonamento('1000')
                } else {
                    setCostoAbbonamento('3600')
                }
            }
        }

    }

    const handleChangeRadioButtonGruppi = (event) => {
        setTipoGruppo(event.target.value)

    }


    const handleChange = (event) => {
        if (event.target.value === "Annuale") {
            setTipoAbbonamento("Annuale")
            setDatePickerAnno(true)
            setDatePickerMese(false)
            setDatePickerGiorno(false)
        }
        if (event.target.value === "Trimestrale") {
            setTipoAbbonamento("Trimestrale")
            setDatePickerAnno(false)
            setDatePickerMese(true)
            setDatePickerGiorno(false)
        }
        if (event.target.value === "Mensile") {
            setTipoAbbonamento("Mensile")
            setDatePickerAnno(false)
            setDatePickerMese(true)
            setDatePickerGiorno(false)
        }
    }

    const isBookableFieldChecked = (index) => {
        //console.log('Im checked' + index + selectedFields.has(index))
        selectedFields.has(index)
    }

    const checkBookableField = (event, index) => {
        event.preventDefault();
        if (event.target.checked) {
            selectedFields.add(index + 1)
        }
        else {
            selectedFields.delete(index + 1)

        }
        setNum(selectedFields.size)
        calcoloCostoAbbonamento()
    };



    const buttonCLickPagamento = () => {

        buttonCLick();

    }

    const buttonCLick = () => {


        setIsClickableButton(false);
        let _dataInizioAbbonamento = '';
        let _dataFineAbbonamento = '';

        if (num == 0) {
            setMsgAlert("Errore: selezionare un abbonamento")
            setAlertError(true);
            setAlertOk(false);
            setIsClickableButton(true);
        } else {
            setAlertError(false);
            if (tipoAbbonamento === "Giornaliero") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();
                let giornoInizio = valueData.date().toString();

                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                if (giornoInizio < 10) {
                    giornoInizio = `0${giornoInizio}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}${giornoInizio}000001`);
                _dataFineAbbonamento = (`${annoInizio}${meseInizio}${giornoInizio}235959`);
            }

            if (tipoAbbonamento === "Mensile") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();

                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}01000001`);
                if (meseInizio === '02') {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}28235959`);
                } else if (meseInizio === '04' || meseInizio === '06' || meseInizio === '09' || meseInizio === '11') {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}30235959`);
                } else {
                    _dataFineAbbonamento = (`${annoInizio}${meseInizio}31235959`);
                }


            }

            if (tipoAbbonamento === "Trimestrale") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();
                let meseFine = (valueData.month() + 3).toString();
                if (valueData.month() > 9) {

                    meseFine = (12).toString();
                }


                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                if (meseFine < 10) {
                    meseFine = `0${meseFine}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}01000001`);
                if (meseFine === '02') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}28235959`);
                } else if (meseFine === '04' || meseFine === '06' || meseFine === '09' || meseFine === '11') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}30235959`);
                } else {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}31235959`);
                }


            }

            if (tipoAbbonamento === "Annuale") {
                let annoInizio = valueData.year().toString();

                _dataInizioAbbonamento = (`${annoInizio}0101000001`);
                _dataFineAbbonamento = (`${annoInizio}1231235959`);
            }


            sendApiData(_dataInizioAbbonamento, _dataFineAbbonamento, tipoAbbonamento);
        }
    }


    const sendApiData = (dataInizioAbbonamento, dataFineAbbonamento) => {
        let arrayCampi = []
        if (num === 2) {
            arrayCampi = [1, 2]
        } else {
            arrayCampi = [1]
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var min = today.getMinutes();
        var sec = today.getSeconds();


        if (hh < 10) {
            hh = '0' + hh;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }

        today = yyyy + mm + dd + hh + min + sec;

        let arrCampi = [...selectedFields];

        console.log(num);
        console.log(arrCampi);
        console.log(dataInizioAbbonamento);
        console.log(dataFineAbbonamento);
        console.log("0");
        console.log(today);
        console.log(today);

        console.log(JSON.stringify({
            "createCheckoutSession": 1,
            "tipoGruppo": tipoGruppo,
            "idCampo": arrCampi,
            "dataInizio": dataInizioAbbonamento,
            "dataFine": dataFineAbbonamento,
            "tipoAbbonamento": tipoAbbonamento,
            "isFattura": isRichiediFattura,
            "insDate": today,
            "updDate": today
        }
        ))

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                "createCheckoutSession": 1,
                "tipoGruppo": tipoGruppo,
                "idCampo": arrCampi,
                "dataInizio": dataInizioAbbonamento,
                "dataFine": dataFineAbbonamento,
                "tipoAbbonamento": tipoAbbonamento,
                "isFattura": isRichiediFattura,
                "insDate": today,
                "updDate": today
            })

        };


        fetch(constant.BASE_URL + "payment_init_gruppi.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json().then(data => {
                        stripePromise.then(stripe => {
                            stripe.redirectToCheckout({
                                sessionId: data.sessionId,
                            });
                        })
                    });
                }
            }).then(handleResult)

    }
    const handleResult = (result) => {
        if (result.error) {
            console.log(result.error.message);
        }
    };

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const paerStyle = {
        padding: 20,
        height: '70vh',
        // width: matches ? '800px' : '300px',
        width: '800px',
        margin: '10px auto'
    }

    function calcolaCosto(num, costoAbbonamento) {
        if (!isRichiediFattura) {
            return num * costoAbbonamento;
        } else {
            return num * costoAbbonamento * 1.22
        }

    }


    return (

        <div style={{
            padding: 0,
            width: '100%',
            margin: 'auto'
        }}>
            {visualizzaPagina == true ?
                <Grid>
                    <InfoUserSocieta />
                    <div
                        style={{
                            padding: 20,
                            width: '75%',
                            margin: 'auto'
                        }}
                        >
                    <Grid align='center'>
                        <FormControl id='FormControl-gruppo'>
                            <h2>Numero atleti</h2>
                            <RadioGroup id='radioGroup-tempo'>
                                <FormControlLabel value={20} control={<Radio onChange={handleChangeRadioButtonGruppi} />} label="Da 11 a 20" />
                                <FormControlLabel value={40} control={<Radio onChange={handleChangeRadioButtonGruppi} />} label="Da 21 a 40" />
                                <FormControlLabel value={41} control={<Radio onChange={handleChangeRadioButtonGruppi} />} label="Maggiore di 40" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <ColoredLine color="black" />
                    <Grid align='center'>
                        {/**/}
                        <h2>Campo</h2>
                        <FormGroup
                            defaultValue='20'>
                            {
                                bookableField.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            style={{ justifyContent: 'center'}}
                                            checked={isBookableFieldChecked(index)}
                                            control={
                                                <Checkbox
                                                    onChange={(event) => checkBookableField(event, index)}
                                                />} label={item}
                                            key={index}
                                        />
                                    )
                                })

                            }
                        </FormGroup>

                        <ColoredLine color="black" />

                        <FormControl id='FormControl-tempo'>
                            <h2>Periodo</h2>
                            <RadioGroup id='radioGroup-tempo'>
                                {/*<FormControlLabel value={5} name={"Giornaliero"} control={<Radio onChange={handleChange} />} label="Giornaliero" />*/}
                                <FormControlLabel value={"Mensile"} control={<Radio onChange={handleChange} />} label="Mensile" />
                                <FormControlLabel value={"Trimestrale"} control={<Radio onChange={handleChange} />} label="Trimestrale" />
                                <FormControlLabel value={"Annuale"} control={<Radio onChange={handleChange} />} label="Annuale" />
                            </RadioGroup>
                        </FormControl>
                        <ColoredLine color="black" />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                            <h2>Calendario</h2>
                            <Stack spacing={3}>
                                {DatePickerAnno ? <DatePicker
                                    views={['year']}
                                    label="Anno"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerMese ? <DatePicker
                                    views={['year', 'month']}
                                    label="Anno e mese di inizio"
                                    minDate={dayjs(dayjs())}
                                    //maxDate={dayjs('2023-06-01')}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerGiorno ? <DatePicker
                                    views={['day']}
                                    label="Data"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                            </Stack>
                        </LocalizationProvider>
                        <ColoredLine color="black" />
                        <Grid align='center'>
                            <FormControl>
                                <FormControlLabel
                                    control={<Switch align='center' color="primary" />}
                                    label="RICHIEDI FATTURA"
                                    align='center'
                                    onChange={() => setIsRichiediFattura(prevCheck => !prevCheck)}
                                    value={isRichiediFattura}
                                    checked={!!isRichiediFattura}
                                />
                            </FormControl>
                        </Grid>
                        <ColoredLine color="black" />
                        <h1>Totale: {calcolaCosto(num, costoAbbonamento)} € </h1>
                        <ColoredLine color="black" />
                        <Button style={{width: 'auto', marginTop: '20px', marginBottom: '20px'}} disabled={isClickableButton ? false : true} variant="contained" margin='normal' size="medium" fullWidth onClick={buttonCLickPagamento}>
                            Scegli piano
                        </Button>
                    </Grid>
                    {alertError ? <Alert padding='20' severity='error'>{msgAlert}</Alert> : <></>}
                    {alertOk ? <Alert severity='success'>{msgAlert}</Alert> : <></>}
                    {/* </Paper> */}
                    </div>
                </Grid> : <></>
            }
        </div >
    )
}

export default AbbonamentoSocietaGruppo;


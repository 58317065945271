

//LAVORO
// const myConstClass = {
//     LOGIN: "http://localhost:3210/Progetti/fidal-bs/php-backend/login.php",
//     RICHIESTAABBONAMENTI: "http://localhost:3210/Progetti/fidal-bs/php-backend/richiestaAbbonamenti.php",
//     INSERTABBONAMENTO: "http://localhost:3210/Progetti/fidal-bs/php-backend/insertAbbonamento.php",
//     RICHIESTACAMPI: "http://localhost:3210/Progetti/fidal-bs/php-backend/richiestaCampi.php",
//     INSERTUTENTE : "http://localhost:3210/Progetti/fidal-bs/php-backend/insertUtente.php",
//     INSERTABBONAMENTOADMIN : "http://localhost:3210/Progetti/fidal-bs/php-backend/insertAbbonamentoAdmin.php",
//     BASE_URL : "http://localhost:3210/Progetti/fidal-bs/php-backend/",


// }
// export default myConstClass;


//CASA
// const myConstClass = {
//     LOGIN: "http://localhost:3210/php-backend/login.php",
//     RICHIESTAABBONAMENTI: "http://localhost:3210/php-backend/richiestaAbbonamenti.php",
//     INSERTABBONAMENTO: "http://localhost:3210/php-backend/insertAbbonamento.php",
//     RICHIESTACAMPI: "http://localhost:3210/php-backend/richiestaCampi.php",
//     INSERTUTENTE : "http://localhost:3210/php-backend/insertUtente.php",
//     INSERTABBONAMENTOADMIN : "http://localhost:3210/php-backend/insertAbbonamentoAdmin.php",
//     BASE_URL : "http://localhost:3210/php-backend/",

// }

// export default myConstClass;





const myConstClass = {
    LOGIN: "https://gravedona.ratiosolutions.net/php-backend/login.php",
    RICHIESTAABBONAMENTI: "https://gravedona.ratiosolutions.net/php-backend/richiestaAbbonamenti.php",
    INSERTABBONAMENTO: "https://gravedona.ratiosolutions.net/php-backend/insertAbbonamento.php",
    RICHIESTACAMPI: "https://gravedona.ratiosolutions.net/php-backend/richiestaCampi.php",
    INSERTUTENTE: "https://gravedona.ratiosolutions.net/php-backend/insertUtente.php",
    INSERTABBONAMENTOADMIN: "https://gravedona.ratiosolutions.net/php-backend/insertAbbonamentoAdmin.php",
    RECUPEROPASS: "https://gravedona.ratiosolutions.net/php-backend/insertAbbonamentoAdmin.php",
    BASE_URL: "https://gravedona.ratiosolutions.net/php-backend/",
}
export default myConstClass;
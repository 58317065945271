
import { DataGrid, itIT, GridToolbar } from '@mui/x-data-grid';
import { Grid, Paper, Button, Box } from '@mui/material';
import InfoUser from './infoUser.js';
import constant from './Costanti/Constant.js'
import Stack from '@mui/material/Stack';

import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from "./Context.js";

import './DataTable.css'


import { createTheme, ThemeProvider } from "@mui/material/styles";

import imageLogoFidal from "./img/logoFidal.jpeg";
import InfoUserAdmin from './InfoUserAdmin.js';



const Utenze = {
    ADMIN: 1,
    USER: 2,
    OPERATORE: 5
}


const columns = [
    { field: 'id', headerName: 'IDAbbonamento', width: 70 },
    { field: 'NomeUtente', headerName: 'Utente', width: 300 },
    { field: 'NomeSocieta', headerName: 'Societa', width: 300 },
    { field: 'NomeCampo', headerName: 'Campo', width: 300 },
    { field: 'dataInizio', headerName: 'Inizio abbonamento', width: 150 },
    { field: 'dataFine', headerName: 'Fine abbonamento', width: 150 },
    { field: 'statoAbbonamento', headerName: 'Stato abbonamento', width: 150 },
    { field: 'dataInserimento', headerName: 'Data inserimento', width: 200 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
];

const rows = [];

const theme = createTheme({}, itIT);

export default function AbbonamentiList() {

    const navigate = useNavigate();

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)


    const [righe, setRighe] = React.useState([]);
    const [visualizzaPagina, setVisualizzaPagina] = React.useState(false);
    const [idAbbonamentoSelezionato, setIdAbbonamentoSelezionato] = React.useState();


    React.useEffect(() => { // Pass in a callback function!
        console.log(`idtipo ${idTipoUtente}`)
        if (idTipoUtente != Utenze.ADMIN && idTipoUtente != Utenze.OPERATORE) {
            navigate('/');
        } else {
            setVisualizzaPagina(true);
        }
    }, []);

    React.useEffect(() => { // Pass in a callback function!
        console.log("test use effect")
        richiestaAbbonamenti()
    }, []);




    const paerStyle = {
        padding: 20,
        //height: '70vh',
        height: '70vh',
        width: '90%',
        margin: '90px auto',
        //backgroundImage: `url(${imageLogoFidal})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

    }





    const richiestaAbbonamenti = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(constant.RICHIESTAABBONAMENTI, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json();

                }
            })//response.json()
            .then(json => gestioneJsonRitornoCampi(json))
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        // fetch(constant.RICHIESTAABBONAMENTI, {
        //     method: 'post',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Headers': '*',
        //     }
        // })
        //     .then(response => {
        //         return response.json()
        //             .then(json => gestioneJsonRitornoCampi(json))

        //     })
    }

    function gestioneJsonRitornoCampi(json) {
        console.log(json);

        let separator = "-"
        let newDate = new Date()
        let date = ("0" + newDate.getDate()).slice(-2);
        let month = (newDate.getMonth() + 1);
        let year = newDate.getFullYear();

        var today = new Date(`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`);


        for(let ii=0; ii<Object.keys(json).length; ii++){
            var date2 = new Date(json[ii].dataFine);
            if(date2>=today){
                json[ii]["statoAbbonamento"] = "ATTIVO"
            }else{
                json[ii]["statoAbbonamento"] = "SCADUTO"
            }

        }
        console.log(json);
        setRighe(json);

    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const handleAggiuntaAbbonamento = (event) => {

        console.log("Nuovo abbonamento");
        navigate("/abbonamentoOperatore");
    };

    const handleCreaQrCode = (event) => {

        console.log("crea qrCode abbonamento");

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", '*')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(
                {
                    "idAbbonamento": idAbbonamentoSelezionato
                }

            ),
            redirect: 'follow'
        };

        fetch(constant.BASE_URL + "creaQrCode.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status == 500) {
                        console.log("errore 500");
                    } else {
                        throw navigate('/');

                    }
                } else {
                    return response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "qrCodeAbbonamento.png"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        //setMsgAlert("Abbonamento inserito correttamente")
                        //setAlertOk(true);
                        //setAlertError(false);
                    });
                }
            })
    };

    return (

        // <div style={{
        //     // display: 'flex',
        //     // flexDirection: 'column',
        //     // alignItems: 'center',
        //     // justifyContent: 'center',
        //     // // Change the size to fit the parent element of this div
        //     // width: '100%',
        //     // height: '100%',


        //     padding: 20,
        //     width: '85%',
        //     margin: 'auto'

        // }}>

            <div>
                <InfoUserAdmin />
                <Paper elevation={24} style={paerStyle}>

                    <DataGrid
                        rows={righe}
                        columns={columns}
                        pageSize={100}
                        rowsPerPageOptions={[20]}
                        //checkboxSelection
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: GridToolbar
                        }}
                        onSelectionModelChange={(ids) => {
                            setIdAbbonamentoSelezionato(ids[0])
                        }}
                    />
                    <ColoredLine color="black" />
                    <Box textAlign='center'>
                        <Stack spacing={2} direction="row">
                            <Button size="medium" variant='contained' className={'aggiungi-abbonamento-button'} onClick={handleAggiuntaAbbonamento}>
                                Aggiungi abbonamento
                            </Button>
                            <Button size="medium" variant='contained' className={'crea-qr-code-button'} onClick={handleCreaQrCode}>
                                Crea qrCode
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
            </div>


    );
}

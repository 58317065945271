import { Box, Grid, Button, InputLabel, Select, Alert, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React, { useContext } from 'react';


import constant from '../Costanti/Constant.js';
import 'dayjs/locale/it';

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { loadStripe } from "@stripe/stripe-js";

import { useNavigate } from "react-router-dom";
import InfoUserSocieta from '../infoUserSocieta';
import { Context } from "../Context.js";
import { ColorLensOutlined, CoPresentOutlined } from '@mui/icons-material';


import './AbbonamentoSocietaCorso.css'
//const label = { inputProps: { 'aria-label': 'Checkbox demo' } }


const Utenze = {
    ADMIN: 1,
    USER: 2,
    SOCIETA: 3
}



const AbbonamentoSocietaCorso = () => {

    const stripePromise = loadStripe("pk_live_51LvMKTIUBaKEna1TVuf3hbmFwVkBBzOEfsm0M7Jp6vsm0i7UsuPG87XyGnD77ANmLPs38bhhKECYn43B0fF9geSL00efgDLihY");
    //const stripePromise = loadStripe("pk_test_51LvMKTIUBaKEna1TRnOLWiJT9RIK89r8m7UIacjqQ83SbIUOgNm3MphWaXYcYUflSnKuTsWV8P5T3wgr1Nxtgduc00difhqW1S");

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)

    const navigate = useNavigate();


    const [costoAbbonamento, setCostoAbbonamento] = React.useState('0');
    const [durataAbbonamento, setDurataAbbonamento] = React.useState('');
    const [tipoGruppo, setTipoGruppo] = React.useState('');

    const [isRichiediFattura, setIsRichiediFattura] = React.useState(false);
    const [costoTotale, setCostoTotale] = React.useState(0);


    const [bookableField, setBookableField] = React.useState([]);
    const [selectedFields, _] = React.useState(new Set());
    const [num, setNum] = React.useState('0');
    const [alertError, setAlertError] = React.useState(false);
    const [alertOk, setAlertOk] = React.useState(false);

    const [DatePickerAnno, setDatePickerAnno] = React.useState(false);
    const [DatePickerMese, setDatePickerMese] = React.useState(false);
    const [DatePickerGiorno, setDatePickerGiorno] = React.useState(false);

    const [msgAlert, setMsgAlert] = React.useState('');
    const [valueData, setValueData] = React.useState(dayjs());


    const [visualizzaPagina, setVisualizzaPagina] = React.useState(false);
    const [isClickableButton, setIsClickableButton] = React.useState(true);

    const [utenti10, setUtenti10] = React.useState(0);
    const [utenti15, setUtenti15] = React.useState(0);

    const handleChange10 = (event) => {
        setUtenti10(event.target.value);
    };

    const handleChange15 = (event) => {
        setUtenti15(event.target.value);
    };

    React.useEffect(() => { // Pass in a callback function!
        calcolaCosto()
    }, [utenti10, utenti15]);



    React.useEffect(() => { // Pass in a callback function!
        if (idTipoUtente != Utenze.SOCIETA) {
            navigate('/');
        } else {
            setVisualizzaPagina(true);
        }
    }, []);








    const buttonCLickPagamento = () => {
        buttonCLick();
    }

    const buttonCLick = () => {


        setIsClickableButton(false);
        let _dataInizioAbbonamento = '';
        let _dataFineAbbonamento = '';


        if (durataAbbonamento === "") {
            setMsgAlert("Errore: indicare il peridio di durata del corso")
            setAlertError(true);
            setAlertOk(false);
            setIsClickableButton(true);
        } else {
            setAlertError(false);

            if (durataAbbonamento === "Trimestrale") {
                let annoInizio = valueData.year().toString();
                let meseInizio = (valueData.month() + 1).toString();
                let meseFine = (valueData.month() + 3).toString();
                if (valueData.month() > 9) {
                    meseFine = (12).toString();
                }


                if (meseInizio < 10) {
                    meseInizio = `0${meseInizio}`;
                }

                if (meseFine < 10) {
                    meseFine = `0${meseFine}`;
                }

                _dataInizioAbbonamento = (`${annoInizio}${meseInizio}01000001`);
                if (meseFine === '02') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}28235959`);
                } else if (meseFine === '04' || meseFine === '06' || meseFine === '09' || meseFine === '11') {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}30235959`);
                } else {
                    _dataFineAbbonamento = (`${annoInizio}${meseFine}31235959`);
                }


            }


            sendApiData(_dataInizioAbbonamento, _dataFineAbbonamento);
        }
    }


    const sendApiData = (dataInizioAbbonamento, dataFineAbbonamento) => {



        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var min = today.getMinutes();
        var sec = today.getSeconds();


        if (hh < 10) {
            hh = '0' + hh;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }

        today = yyyy + mm + dd + hh + min + sec;


        console.log(dataInizioAbbonamento);
        console.log(dataFineAbbonamento);
        console.log(today);

        console.log(JSON.stringify({
            "createCheckoutSession": 1,
            "dataInizio": dataInizioAbbonamento,
            "dataFine": dataFineAbbonamento,
            "durataAbbonamento": durataAbbonamento,
            "numeroIscritto10Euro": utenti10,
            "numeroIscritto15Euro": utenti15,
            "insDate": today,
            "updDate": today
        }
        ))

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                "createCheckoutSession": 1,
                "dataInizio": dataInizioAbbonamento,
                "dataFine": dataFineAbbonamento,
                "durataAbbonamento": durataAbbonamento,
                "numeroIscritto10Euro": utenti10,
                "numeroIscritto15Euro": utenti15,
                "insDate": today,
                "updDate": today
            })

        };


        fetch(constant.BASE_URL + "payment_init_gruppi_corsi.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json().then(data => {
                        stripePromise.then(stripe => {
                            stripe.redirectToCheckout({
                                sessionId: data.sessionId,
                            });
                        })
                    });
                }
            }).then(handleResult)

    }
    const handleResult = (result) => {
        if (result.error) {
            console.log(result.error.message);
        }
    };


    const handleChange = (event) => {
        if (event.target.value === "Trimestrale") {
            setDurataAbbonamento("Trimestrale")
            setDatePickerAnno(false)
            setDatePickerMese(true)
            setDatePickerGiorno(false)
        }
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const paerStyle = {
        padding: 20,
        height: '70vh',
        // width: matches ? '800px' : '300px',
        width: '800px',
        margin: '10px auto'
    }

    function calcolaCosto() {
        var costoTotale = 0;

        var costo10 = utenti10 * 10
        var costo15 = utenti15 * 15

        costoTotale = costo10 + costo15;

        return costoTotale

    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (

        <div style={{
            padding: 0,
            width: '100%',
            margin: 'auto'
        }}>
            {visualizzaPagina == true ?
                <Grid>
                    <InfoUserSocieta />
                    <div
                        style={{
                            padding: 20,
                            width: '75%',
                            margin: 'auto'
                        }}
                    >

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} align='center'>
                            <Grid item xs={6} className={'numero-persone-select'}>
                                <Box x={{ m: 1, width: 300 }}>
                                    <h3>Numero di persone che fa 2 giornate a settimana (10€)</h3>
                                    <FormControl fullWidth>

                                        <InputLabel id="demo-simple-select-label">Numero utenti</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={utenti10}
                                            label="NumeroUtenti10"
                                            onChange={handleChange10}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={0}>0</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={11}>11</MenuItem>
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={13}>13</MenuItem>
                                            <MenuItem value={14}>14</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={16}>16</MenuItem>
                                            <MenuItem value={17}>17</MenuItem>
                                            <MenuItem value={18}>18</MenuItem>
                                            <MenuItem value={19}>19</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={21}>21</MenuItem>
                                            <MenuItem value={22}>22</MenuItem>
                                            <MenuItem value={23}>23</MenuItem>
                                            <MenuItem value={24}>24</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={26}>26</MenuItem>
                                            <MenuItem value={27}>27</MenuItem>
                                            <MenuItem value={28}>28</MenuItem>
                                            <MenuItem value={29}>29</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={31}>31</MenuItem>
                                            <MenuItem value={32}>32</MenuItem>
                                            <MenuItem value={33}>33</MenuItem>
                                            <MenuItem value={34}>34</MenuItem>
                                            <MenuItem value={35}>35</MenuItem>
                                            <MenuItem value={36}>36</MenuItem>
                                            <MenuItem value={37}>37</MenuItem>
                                            <MenuItem value={38}>38</MenuItem>
                                            <MenuItem value={39}>39</MenuItem>
                                            <MenuItem value={40}>40</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6} className={'numero-persone-select'}>
                                <Box x={{ m: 1, width: 300 }}>
                                    <h3>Numero di persone che fa 3 giornate a settimana (15€)</h3>
                                    <FormControl fullWidth>

                                        <InputLabel id="demo-simple-select-label">Numero utenti</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={utenti15}
                                            label="NumeroUtenti15"
                                            onChange={handleChange15}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value={0}>0</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={11}>11</MenuItem>
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={13}>13</MenuItem>
                                            <MenuItem value={14}>14</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={16}>16</MenuItem>
                                            <MenuItem value={17}>17</MenuItem>
                                            <MenuItem value={18}>18</MenuItem>
                                            <MenuItem value={19}>19</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={21}>21</MenuItem>
                                            <MenuItem value={22}>22</MenuItem>
                                            <MenuItem value={23}>23</MenuItem>
                                            <MenuItem value={24}>24</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={26}>26</MenuItem>
                                            <MenuItem value={27}>27</MenuItem>
                                            <MenuItem value={28}>28</MenuItem>
                                            <MenuItem value={29}>29</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={31}>31</MenuItem>
                                            <MenuItem value={32}>32</MenuItem>
                                            <MenuItem value={33}>33</MenuItem>
                                            <MenuItem value={34}>34</MenuItem>
                                            <MenuItem value={35}>35</MenuItem>
                                            <MenuItem value={36}>36</MenuItem>
                                            <MenuItem value={37}>37</MenuItem>
                                            <MenuItem value={38}>38</MenuItem>
                                            <MenuItem value={39}>39</MenuItem>
                                            <MenuItem value={40}>40</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid align='center'>
                            <FormControl id='FormControl-tempo'>
                                <h2>Periodo</h2>
                                <RadioGroup id='radioGroup-tempo'>
                                    {/*<FormControlLabel value={5} name={"Giornaliero"} control={<Radio onChange={handleChange} />} label="Giornaliero" />*/}
                                    <FormControlLabel value={"Trimestrale"} control={<Radio onChange={handleChange} />} label="Trimestrale" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <ColoredLine color="black" />
                    </Box>
                    <Grid align='center'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                            <h2>Calendario</h2>
                            <Stack spacing={3}>
                                {DatePickerAnno ? <DatePicker
                                    views={['year']}
                                    label="Anno"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerMese ? <DatePicker
                                    views={['year', 'month']}
                                    label="Anno e mese di inizio"
                                    minDate={dayjs(dayjs())}
                                    //maxDate={dayjs('2023-06-01')}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                                {DatePickerGiorno ? <DatePicker
                                    views={['day']}
                                    label="Data"
                                    minDate={dayjs(dayjs())}
                                    value={valueData}
                                    onChange={(newValue) => {
                                        setValueData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                /> : <></>}
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <ColoredLine color="black" />
                    <Grid align='center'>


                        <h1>Totale: {calcolaCosto()} € </h1>
                        <ColoredLine color="black" />
                        <Button style={{width: 'auto', marginTop: '20px', marginBotton: '20px'}} disabled={isClickableButton ? false : true} variant="contained" margin='normal' size="medium" fullWidth onClick={buttonCLickPagamento}>
                            Scegli piano
                        </Button>
                    </Grid>
                    {alertError ? <Alert padding='20' severity='error'>{msgAlert}</Alert> : <></>}
                    {alertOk ? <Alert severity='success'>{msgAlert}</Alert> : <></>}
                    {/* </Paper> */}
                    </div>
                </Grid> : <></>
            }
        </div >
    )
}

export default AbbonamentoSocietaCorso;


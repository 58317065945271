import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import mainLogo from './ratio_logo.svg';
import fidalLogo from './fidalLogo.jpg';
import Login from './Login/Login';
import { Context } from "./Context.js";
import { useState } from 'react';
import { Avatar, Grid } from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';

import { useNavigate } from "react-router-dom";



const drawerWidth = 240;



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function InfoUserSocieta() {

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function clickMenuSx(idSelezionato) {
        console.log(idSelezionato)

        /*

        0 --> Iscrizione atleti singoli
        1 --> Iscrizione gruppo atleti

        */

        switch (idSelezionato) {
            case 0:
                return navigate('/abbonamentoSocietaGruppo');
            case 1:
                return navigate('/abbonamentoSocietaCorso');
            default:
                return 'foo';
        }

    }

    const iconsArray = [
        {
            id: 0,
            avatarIcon: (<PersonIcon />),
            message: "Iscrizione società",
        },
        {
            id: 1,
            avatarIcon: (<GroupsIcon />),
            message: "Acquisto abbonamento corsi",
        },
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="relative" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Atletica Alto Lario - SOCIETA'
                    </Typography>
                    <Typography variant="h6" component="div" dx={{ flexGrow: 1 }}>
                        {nome}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {iconsArray.map((item, index) => (
                        <ListItem key={item.id} disablePadding  >
                            <ListItemButton onClick={(event) => clickMenuSx(item.id)}>
                                <ListItemIcon>
                                    {item.avatarIcon}
                                </ListItemIcon>
                                <ListItemText primary={item.message} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>

        </Box>

    );

}
import { Button, FormGroup, Grid, Box, LinearProgress, Alert } from '@mui/material';
import React, { useContext } from 'react';


import TextField from '@mui/material/TextField';
import 'dayjs/locale/it';
import constant from './Costanti/Constant.js';

import InfoUserAdmin from './InfoUserAdmin';



import { Context } from "./Context.js";


import './InvioEmail.css'

const InvioEmail = () => {

    const { nome, setNome, token, setToken, idTipoUtente, setidTipoUtente } = useContext(Context)

    const [isClickableButton, setIsClickableButton] = React.useState(true);
    const [isVisibleBarloading, setIsVisibleBarloading] = React.useState(false);
    const [msgAlertErrorEmail, setMsgAlertErrorEmail] = React.useState('');
    const [alertError, setAlertError] = React.useState(false);

    const [oggettoEmail, setoggettoEmail] = React.useState('');
    const [corpoEmail, setCorpoEmail] = React.useState('');

    const handleChangeOggettoEmail = (event) => {
        setoggettoEmail(event.target.value);
    };

    const handleChangeCorpoEmail = (event) => {
        setCorpoEmail(event.target.value);
    };

    const handleInvioEmail = (event) => {
        setIsClickableButton(false)
        setIsVisibleBarloading(true)
        invioEmailMultipla(oggettoEmail, corpoEmail, token)
        console.log("email inviata");
    };

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    const invioEmailMultipla = (oggetto, corpo, token) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", 'Access-Control-Allow-Headers , X-Requested-With, Content-Type, Authorization')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        myHeaders.append("Authorization", `Bearer ${token}`);
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                "oggetto": oggetto,
                "corpo": corpo
            })
        };


        fetch(constant.BASE_URL + "invioEmailMultipla.php", requestOptions)
            .then(response => {

                if (!response.ok) throw new Error(response.status);
                else return response.json()
                    .then(json => responseInvioEmailMultipla(json))


            })
    }


    const responseInvioEmailMultipla = async (json) => {
        console.log(json)
        const _risultato = json["risultato"]
        //back tick = alt + 96
        console.log(`risultato: ${_risultato}`)
        if (_risultato == 200) {
            setIsClickableButton(true)
            setIsVisibleBarloading(false)
            let messaggio = "Invio andato a buon fine"
            setMsgAlertErrorEmail(messaggio)
        } else {
            setIsClickableButton(true)
            setIsVisibleBarloading(false)
            let emailInErrore = json["emailInErrore"]
            let messaggio = "Ai seguenti indirizzi non è stato possibile inviare l'email:"
            for (let i = 0; i < emailInErrore.length; i++) {
                messaggio = messaggio + "\n" + emailInErrore[i]
            }

        }

    }

    return (


        // <div style={{
        //     // display: 'flex',
        //     // flexDirection: 'column',
        //     // alignItems: 'center',
        //     // justifyContent: 'center',
        //     // // Change the size to fit the parent element of this div
        //     // width: '100%',
        //     // height: '100%',

        //     padding: 100,
        //     // width: matches ? '800px' : '300px',
        //     width: '75%',
        //     margin: 'auto'
        // }}>
            <Grid>
                <InfoUserAdmin />
                <div
                        className={'invio-mail-multiplo-container'}
                    style={{
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    // // Change the size to fit the parent element of this div
                    // width: '100%',
                    // height: '100%',

                    padding: 100,
                    // width: matches ? '800px' : '300px',
                    width: '75%',
                    margin: 'auto'
                }}>
                    <Grid align='center'>
                        <h2>INVIO EMAIL MULTIPLO</h2>
                        <FormGroup>
                            <TextField
                                id="oggettoEmail"
                                label="Oggetto email"
                                margin="normal"
                                value={oggettoEmail}
                                onChange={handleChangeOggettoEmail}
                                className={'input-text'}
                            />
                            <TextField
                                id="corpoEmail"
                                label="Corpo email"
                                margin="normal"
                                multiline
                                rows={10}
                                value={corpoEmail}
                                onChange={handleChangeCorpoEmail}
                                className={'input-text'}

                            />
                            <ColoredLine color="black" />
                            <Button disabled={isClickableButton ? false : true} variant="contained" margin='normal' size="medium" fullWidth onClick={handleInvioEmail}>
                                Invia Email
                            </Button>
                            <ColoredLine color="black" />

                            <Box sx={{ width: '100%' }}>
                                {isVisibleBarloading ? <LinearProgress /> : <></>}
                            </Box>
                        </FormGroup>
                        {msgAlertErrorEmail.length !== 0 ? <Alert padding='20' severity='warning'>{msgAlertErrorEmail}</Alert> : <></>}
                    </Grid>
                </div>
            </Grid>
        //</div>

    );

}



export default InvioEmail;

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import constant from '../Costanti/Constant.js';
import { Navigate, useNavigate } from "react-router";


const ProductDisplay = () => (
    <section>
        <div className="product">
            <img
                src="https://i.imgur.com/EHyR2nP.png"
                alt="The cover of Stubborn Attachments"
            />
            <div className="description">
                <h3>Stubborn Attachments</h3>
                <h5>$20.00</h5>
            </div>
        </div>
    
    </section>
);

const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function Pagamento() {
    const [message, setMessage] = useState("");
    const stripePromise = loadStripe("pk_live_51LvMKTIUBaKEna1TVuf3hbmFwVkBBzOEfsm0M7Jp6vsm0i7UsuPG87XyGnD77ANmLPs38bhhKECYn43B0fF9geSL00efgDLihY");
    //const stripePromise = loadStripe("pk_test_51LvMKTIUBaKEna1TRnOLWiJT9RIK89r8m7UIacjqQ83SbIUOgNm3MphWaXYcYUflSnKuTsWV8P5T3wgr1Nxtgduc00difhqW1S");
    const navigate = useNavigate()

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const handleResult = (result) => {
        if (result.error) {
            console.log(result.error.message);
        }
    };

    const createCheckoutSession = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", 'application/json')
        myHeaders.append("Access-Control-Allow-Origin", '*')
        myHeaders.append("Access-Control-Allow-Headers", '*')
        myHeaders.append("Access-Control-Allow-Methods", 'PUT, GET, POST, DELETE, OPTIONS')
        myHeaders.append("Cache-Control", 'no-cache, no-store, must-revalidate')
        myHeaders.append("Pragma", 'no-cache')
        //myHeaders.append("Authorization", `Bearer fdasfa`);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(
                {createCheckoutSession: 1}

            ),
            redirect: 'follow'
        };

        fetch(constant.BASE_URL + "payment_init.php", requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw navigate('/');
                } else {
                    return response.json().then(data => {
                        stripePromise.then(stripe => {
                            stripe.redirectToCheckout({
                                sessionId: data.sessionId,
                            });
                        })
                    });
                }
            }).then(handleResult)


    };




    return (
        <button type="submit" onClick={createCheckoutSession}>
            Checkout
        </button>
    )
}
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Login/Login";
import Abbonamento from "./Abbonamento/Abbonamento";
import Iscrizione from "./Iscrizione";
import AbbonamentiList from "./DataTable";
import UserList from "./UtentiDataTable";
import AbbonamentoOpertore from "./Abbonamento/NuovoAbbonamentoDaOperatore";
import InvioEmail from "./InvioEmail";
import GuardedRoute from "./GuardedRoute";
import { Context } from "./Context.js";
import { useState } from 'react';
import Pagamento from './Abbonamento/Pagamento'
import AbbonamentoSocietaGruppo from "./Abbonamento/AbbonamentoSocietaGruppo";
import RecuperoPassword from "./RecuperoPass";
import AbbonamentoSocietaCorso from "./Abbonamento/AbbonamentoSocietaCorso";


function App() {
	const [nome, setNome] = useState();
	const [token, setToken] = useState();
	const [idTipoUtente, setidTipoUtente] = useState();
	return (
		<Context.Provider value={{ nome, setNome, token, setToken, idTipoUtente, setidTipoUtente }}>
			<div style={{overflowX: 'hidden', width: '100%'}}>
				<BrowserRouter>
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/abbonamento" element={<Abbonamento />} />
						<Route exact path="/abbonamentoOperatore" element={<AbbonamentoOpertore />} />
						<Route path='/listaAbbonamenti' element={(<AbbonamentiList />)} />
						<Route path='/listaUtenti' element={(<UserList />)} />
						<Route path='/pagamento' element={(<Pagamento />)} />
						<Route exact path="/iscrizione" element={<Iscrizione />} />
						<Route exact path="/invioEmail" element={<InvioEmail />} />
						<Route exact path="/abbonamentoSocietaGruppo" element={<AbbonamentoSocietaGruppo />} />
						<Route exact path="/recuperoPassword" element={<RecuperoPassword />} />
						<Route exact path="/abbonamentoSocietaCorso" element={<AbbonamentoSocietaCorso />} />
					</Routes>
				</BrowserRouter>
			</div>
		</Context.Provider>
	);
}

export default App;


import { FormControlLabel, Grid, Box, Button, Alert, Switch, TextField, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/it';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';

import React from 'react';
import { getValue } from '@mui/system';
import { useNavigate } from "react-router-dom";

import constant from './Costanti/Constant.js';

import './Iscrizione.css'



export default function Iscrizione() {

    const navigate = useNavigate();

    const [valueData, setValueData] = React.useState(dayjs());
    const [nome, setNome] = React.useState('')
    const [nomeSocieta, setNomeSocieta] = React.useState('')
    const [tesseraFidalSocieta, setTesseraFidalSocieta] = React.useState('')
    const [telefonoSocieta, setTelefonoSocieta] = React.useState('')
    const [cognome, setCognome] = React.useState('')
    const [codFisc, setCodFisc] = React.useState('')
    const [indirizzo, setIndrizzo] = React.useState('')
    const [comune, setComune] = React.useState('')
    const [citta, setCitta] = React.useState('')
    const [provincia, setProvincia] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordConferma, setPasswordConferma] = React.useState('')
    const [msgAlert, setMsgAlert] = React.useState('');

    const [isSocieta, setIsSocieta] = React.useState(false);

    const [alertError, setAlertError] = React.useState(false);
    const [alertOk, setAlertOk] = React.useState(false);


    const paerStyle = {
        padding: 20,
        height: '70vh',
        width: 800,
        margin: '10px auto'
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );


    function handle() {

        console.log("pass: " + password)
        console.log("pass conferma: " + passwordConferma)

        if(password === passwordConferma){
            inviorRichiestaIscrizione()
        }else{
            setAlertError(true);
            setAlertOk(false);
            setMsgAlert("Attenzione le password non coincidono");
        }

    }

    const inviorRichiestaIscrizione = () => {

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var min = today.getMinutes();
        var sec = today.getSeconds();


        if (hh < 10) {
            hh = '0' + hh;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }

        today = yyyy + mm + dd + hh + min + sec;

        let annoInizio = valueData.year().toString();
        let meseInizio = (valueData.month() + 1).toString();
        let giornoInizio = valueData.date().toString();

        if (meseInizio < 10) {
            meseInizio = `0${meseInizio}`;
        }

        if (giornoInizio < 10) {
            giornoInizio = `0${giornoInizio}`;
        }

        let annoDiNascitaTradotto = (`${annoInizio}${meseInizio}${giornoInizio}000000`);
        console.log(annoDiNascitaTradotto);

        const body = JSON.stringify({
            "nome": nome,
            "cognome": cognome,
            "codiceFiscale": codFisc,
            "indirizzo": indirizzo,
            "comune": comune,
            "provincia": provincia,
            "dataDiNascita": annoDiNascitaTradotto,
            "numeroTelefono": telefono,
            "email": email,
            "password": password,
            "societa": isSocieta,
            "nomeSocieta": nomeSocieta,
            "tesseraSocieta": tesseraFidalSocieta,
            "telefonoSocieta": telefonoSocieta,
            "insDate": today,
        });

        console.log(nome)
        console.log(cognome)
        console.log(codFisc)
        console.log(indirizzo)
        console.log(comune)
        console.log(provincia)
        console.log(annoDiNascitaTradotto)
        console.log(telefono)
        console.log(email)
        console.log(password)
        console.log(today)

        fetch(constant.INSERTUTENTE, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
            },
            body: body,
        })
            .then(response => {
                if (!response.ok) throw setMsgAlert("Errore inserimento utente");
                else return response.json();
            })//response.json()
            .then(json => gestioneJson(json))
    }

    const gestioneJson = async (json) => {

        console.log(json)

        const _messaggio = json["messaggio"]
        const _risultato = json["risultato"]

        console.log(_risultato);
        console.log(_messaggio);

        if (_risultato == 500) {
            setAlertError(true);
            setAlertOk(false);
            setMsgAlert(_messaggio);

        } else {
            setAlertError(false);
            setAlertOk(true);
            setMsgAlert("Utente creato correttamente\nVerrai indirizzato automaticamente al login");
            await delay(5000);
            navigate('/login')
        }




    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'left',
            // Change the size to fit the parent element of this div
            width: '100%',
            height: '100%',
        }}>
            <Grid align='center'>
                {/* <Paper elevation={10} style={paerStyle}> */}
                <div align='center'>
                    <h2>Iscriviti</h2>
                    <ColoredLine color="black" />
                    <Grid align='center'>
                        <FormControl component="fieldset">
                            <FormControlLabel
                                control={<Switch align='center' color="primary" />}
                                label={isSocieta ? "SOCIETA'":"UTENTE"}
                                align='center'
                                onChange={() => setIsSocieta(prevCheck => !prevCheck)}
                                value={isSocieta}
                                checked={!!isSocieta}
                            />
                        </FormControl>
                    </Grid>
                    {
                        isSocieta ?
                            <div>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid align='center'>
                                        <TextField id="nomeSocietaTF" className={'input-text'} label="Nome societa" onChange={(event) => { setNomeSocieta(event.target.value) }} />
                                        <TextField id="codiceFidalTF" className={'input-text'} label="Codice FIDAL" onChange={(event) => { setTesseraFidalSocieta(event.target.value) }} />
                                        <TextField id="telefonoTF" className={'input-text'} label="Numero di telefono" onChange={(event) => { setTelefonoSocieta(event.target.value) }} />
                                    </Grid>
                                </Box>
                            </div> :
                            <div>
                                <h2>Dati anagrafici</h2>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid align='center'>
                                        <TextField id="nomeTF" className={'input-text'} label="Nome" onChange={(event) => { setNome(event.target.value) }} />
                                        <TextField id="congomeTF" className={'input-text'} label="Cognome" onChange={(event) => { setCognome(event.target.value) }} />
                                        <TextField id="codFiscaleTF" className={'input-text'} label="Codice Fiscale" onChange={(event) => { setCodFisc(event.target.value) }} />
                                    </Grid>
                                    <Grid align='center'>
                                        <TextField id="indirizzoTF" className={'input-text'} label="Indirizzo" onChange={(event) => { setIndrizzo(event.target.value) }} />
                                        <TextField id="comuneTF" className={'input-text'} label="Comune" onChange={(event) => { setComune(event.target.value) }} />
                                    </Grid>
                                    <Grid align='center'>
                                        <TextField id="provinciaTF" className={'input-text'} label="Provincia" onChange={(event) => { setProvincia(event.target.value) }} />
                                        <LocalizationProvider className={'input-text'} dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                                            <DatePicker
                                                className={'input-text'}
                                                views={['year', 'month', 'day']}
                                                label="Data di nascita"
                                                maxDate={dayjs(dayjs())}
                                                value={valueData}
                                                onChange={(newValue) => {
                                                    setValueData(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                        <TextField id="phoneTF" className={'input-text'} label="Numero di telefono" onChange={(event) => { setTelefono(event.target.value) }} />
                                    </Grid>
                                </Box>
                            </div>
                    }
                    <ColoredLine color="black" />
                    <h2>Dati di accesso</h2>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Grid align='center'>
                            <TextField id="emailTF" className={'input-text'} label="Email" onChange={(event) => { setEmail(event.target.value) }} />
                            <TextField id="passTF" className={'input-text'} label="Password" type="password"
                                autoComplete="current-password" onChange={(event) => { setPassword(event.target.value) }} />
                            <TextField id="passCTF" className={'input-text mb-5'} label="Conferma Password" type="password"
                                autoComplete="current-password" onChange={(event) => { setPasswordConferma(event.target.value) }} />
                        </Grid>
                    </Box>
                    <div style={{
                        alignItems: 'left',
                        justifyContent: 'left',
                    }}>
                        <small>
                            PRENDI VISIONE DELL'INFORMATIVA PRIVACY ART. 13 REGOLAMENTO GENERALE EUROPEO SULLA PROTEZIONE DEI DATI UE 2016/679:<br />
                            1.    Il Titolare del trattamento dei suoi dati personali (il "Titolare") è la FIDAL BRESCIA con sede in via Luigi Bazoli 6, 25127 Brescia.<br />

                            2.    I dati personali inseriti nel presente form sono trattati per gestire la sua richiesta (es. prenotazione abbonamento ai campi di atletica e invio di tutte le comunicazioni di servizio relative). <br /> La base giuridica è l'esecuzione della sua richiesta rientrante nell'esecuzione di un contratto o di misure precontrattuali adottate su richiesta dell'interessato. <br />

                            3.    I dati personali trattati sono: il suo nominativo, i suoi recapiti (es. telefono e/o e-mail), codice fiscale, data di nascita, indirizzo, dati di accesso all'account.<br />

                            4.    I suoi dati personali potrebbero essere comunicati ad altri soggetti terzi come società di consulenza o altri gestori di campi all'interno della federazione.<br />

                            5.    I suoi dati personali saranno trattati per il tempo strettamente necessario al perseguimento della finalità sopra indicate.<br />

                            6.    Lei può richiedere al Titolare l'accesso ai suoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati personali che la riguardano o <br /> di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati. Per esercitare questi diritti può scrivere all'indirizzo indicato al punto 1 o inviare una e-mail a <br /> sanpolino@fidalbrescia.it. Lei, inoltre, ha il diritto di proporre reclamo all'Autorità Garante per la protezione dei dati personali.<br />

                            7.    Il conferimento dei suoi dati è necessario per dare esecuzione alla sua richiesta; qualora decidesse di non fornirli, il Titolare sarà impossibilitato a darle un riscontro.<br />

                            8.    I suoi dati non sono soggetti ad alcun processo decisionale automatizzato, compresa la profilazione.<br />
                        </small>
                    </div>
                    <Grid
                        paddingTop={3}
                        paddingLeft={50}
                        paddingRight={50}
                        className={'iscriviti-button'}
                    >
                        <Button variant="contained" margin='normal' size="medium" fullWidth onClick={handle}>
                            ISCRIVITI
                        </Button>
                    </Grid>
                </div>
                {alertError ? <Alert padding='20' severity='error'>{msgAlert}</Alert> : <></>}
                {alertOk ? <Alert severity='success'>{msgAlert}</Alert> : <></>}
                {/* </Paper> */}
            </Grid >
        </div >

    );
}
